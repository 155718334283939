<template>
  <v-container>

    <v-row>
      <v-col cols="12">
        <h1 class="display-1">
          Login / Register
        </h1>
      </v-col>
      <v-row align="center" justify="space-around">
        <v-col align-self="center" cols="12" md="6">
          <login-account />
        </v-col>
        <v-col align-self="center" cols="12" md="6">
          <register-account />
        </v-col>
      </v-row>
      <v-col>
        <v-btn :to="{ name: 'GetStarted' }">Let's Get Started</v-btn>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import RegisterAccount from '@/components/RegisterAccount.vue'
import LoginAccount from '@/components/LoginAccount.vue'

// import { mapState } from 'vuex'

export default {
  name: 'UserLogin',
  data() {
    return {}
  },
  components: {
    RegisterAccount,
    LoginAccount,
  },
  computed: {
    // ...mapState(['user'])
  },
  methods: {
    loggedIn() {
      this.$store.dispatch('loggedIn')
    },
    signOut() {
      this.$store.dispatch('signOut')
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.$store.dispatch('loggedIn')
  },
}
</script>

<style scoped></style>
